import React, { useCallback } from 'react';
import {
	Button,
	Flex,
	Text,
	Link as ChakraLink,
	Box,
	VStack,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, ModalWrapper } from '../../components';
import { RootStore } from '../../redux/createStore';
import {
	setShowTelegramModal,
	setShowLoginFlow,
} from '../../redux/reducers/login-flow.reducer';
import { useGetOtpCode } from '../../queries';
import { BOT_USERNAME } from '../../tg-config';
import { useTelegram } from '../../services';

export const AuthModal: React.FC = () => {
	const dispatch = useDispatch();
	const tg = useTelegram();
	const { telegramConnected, showLoginFlow, userId, exchangingCodesLoading } =
		useSelector((state: RootStore) => state.loginFlow);

	const sendCodeToBot = useCallback(
		(token: string) => {
			(async () => {
				await tg?.actions.proxy.openChatByUsername({
					username: BOT_USERNAME,
					startParam: token,
				});
			})();
		},
		[tg],
	);

	const getOtpCodeMutation = useGetOtpCode(sendCodeToBot);

	const onConnectTelegram = () => {
		dispatch(setShowLoginFlow(false));
		dispatch(setShowTelegramModal(true));
	};

	const onCRMLogin = () => {
		if (userId) {
			getOtpCodeMutation.mutate(+userId);
		}
	};

	return (
		<ModalWrapper
			isOpen={showLoginFlow}
			shouldClose={false}
			modalContentProps={{ maxW: '400px', p: '40px' }}
			modalBodyProps={{ p: '40px 18px 0px 18px' }}>
			<Flex align={'center'} flexDirection={'column'} gap={'32px'}>
				<Box w={'156px'} minW={'156px'} h={'37px'} mb={'10px'}>
					<Icon
						name={'logo'}
						width={'100%'}
						height={'100%'}
						strokeColor={'none'}
					/>
				</Box>
				<Text fontSize={'20px'} lineHeight={'24px'} fontWeight={'600'}>
					Log in
				</Text>
				<VStack
					spacing="12px"
					width="60%"
					height={'118px'}
					justifyContent={'center'}>
					{!telegramConnected && (
						<VStack w="full" spacing="8px">
							<Text
								color="primary"
								fontSize="12px"
								lineHeight="13px"
								fontWeight={400}>
								Step 1:
							</Text>
							<Button
								w="100%"
								isDisabled={telegramConnected}
								onClick={onConnectTelegram}>
								Connect Telegram
							</Button>
						</VStack>
					)}
					<VStack w="full" spacing="8px">
						<Text
							color="primary"
							fontSize="12px"
							lineHeight="13px"
							fontWeight={400}>
							Step 2:
						</Text>
						<Button
							w="100%"
							isDisabled={!telegramConnected}
							onClick={onCRMLogin}
							isLoading={exchangingCodesLoading}>
							Log in to CRM
						</Button>
					</VStack>
				</VStack>
				<Text
					fontSize={'12px'}
					lineHeight={'18px'}
					textAlign={'center'}
					color={'tertiary'}>
					By using DISE, you agree to the{' '}
					<ChakraLink href="#" fontWeight={500}>
						Terms of Service
					</ChakraLink>{' '}
					and{' '}
					<ChakraLink href="#" fontWeight={500}>
						Data Processing Agreement
					</ChakraLink>
					.
				</Text>
			</Flex>
		</ModalWrapper>
	);
};
