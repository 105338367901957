/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import * as signalR from '@microsoft/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthUser } from '../../redux/reducers/auth.reducer';
import { setConnectionState } from '../../redux/reducers/signal-r.reducer';
import { setWorkspaceTelegramIds } from '../../redux/reducers/workspace.reducer';
import { RootStore } from '../../redux/createStore';
import {
	useGetWorkspaceCardsStatuses,
	useGetCurrentUser,
	useGetWorkspaceMembers,
	useGetWorkspaceTelegramIds,
	useGetTgFolders,
	useSetUserTimeZone,
} from '../../queries';
import { SignalRProvider, useTelegram } from '../../services';
import { getCurrentUserTimeZone } from '../../utils';
import { useSubscribeToEvents } from './hooks';
import { useGetWorkspaceId } from '../../hooks';
import { resetChatsIsRefetchDisabledState } from '../../redux/reducers/telegram-state.reducer';
import { useAnalytics } from '../../analytics/context';
import { fetchTgUser } from '../../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';

export const MainLayout: React.FC = () => {
	const dispatch = useDispatch();
	const workspaceId = useGetWorkspaceId();
	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	const { user } = useSelector((state: RootStore) => state.auth);
	const { accessToken } = useSelector((state: RootStore) => state.auth.token);
	const tg = useTelegram();
	const queryClient = useQueryClient();
	useGetWorkspaceCardsStatuses(user.isOnboarded && !!workspaceId);
	useGetWorkspaceMembers(user.isOnboarded && !!workspaceId);
	useGetTgFolders();
	useSubscribeToEvents();

	const { data, isLoading } = useGetCurrentUser(true);
	const { data: workspaceTelegramIds } = useGetWorkspaceTelegramIds();
	const setUserTimeZoneMutation = useSetUserTimeZone();

	const analytics = useAnalytics();

	useEffect(() => {
		if (tg && isSynced && data?.value?.workSpace && !isLoading) {
			const workspace = data.value.workSpace;
			
			analytics.group(workspace.id + "", {
				name: workspace.name,
				avatarUrl: workspace.avatarUrl,
				workspaceId: workspace.id,
			});
		}
	}, [tg, isSynced, data, isLoading]);
	

	React.useEffect(() => {
		if (workspaceId && data?.value?.workSpace && !isLoading) {
			analytics.track("DASHBOARD_VIEWED", {
				workspaceId: workspaceId
			})	
		}
	},[workspaceId, data, isLoading])

	useEffect(() => {
		if (!isLoading && data?.value) {
			dispatch(setAuthUser(data.value));
		}
		if (!isLoading) {
			const currentUserTimeZone = getCurrentUserTimeZone();
			if (
				!data?.value?.timeZoneId ||
				data?.value?.timeZoneId !== currentUserTimeZone
			) {
				setUserTimeZoneMutation.mutate(currentUserTimeZone);
			}
		}
	}, [data, isLoading, dispatch]);

	useEffect(() => {
		if (workspaceTelegramIds?.value) {
			dispatch(
				setWorkspaceTelegramIds(
					workspaceTelegramIds.value?.telegramUserIds,
				),
			);
		}
	}, [workspaceTelegramIds, dispatch]);

	useEffect(() => {
		dispatch(resetChatsIsRefetchDisabledState());
	}, []);

	return (
		<SignalRProvider
			connectEnabled={!!accessToken}
			accessTokenFactory={() => accessToken}
			dependencies={[accessToken]}
			url={import.meta.env.VITE_API_BASE_URL + '/hub'}
			skipNegotiation={true}
			onOpen={() => {
				dispatch(setConnectionState(true));
				console.log('[SignalR] connect open');
			}}
			onReconnect={() => {
				console.log('[SignalR] reconnect');
				dispatch(setConnectionState(true));
			}}
			onClosed={error => {
				dispatch(setConnectionState(false));
				console.log(error, '[SignalR] signalR closed');
			}}
			onError={async error => {
				console.log(error, '[SignalR] signal R onError');
			}}
			// logger={signalR.LogLevel.Trace}
			logMessageContent={true}
			transport={signalR.HttpTransportType.WebSockets}>
			<Box width="full">
				<Outlet />
			</Box>
		</SignalRProvider>
	);
};
